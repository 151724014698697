import React from 'react';
import PropTypes from 'prop-types';
import {
    MenuSecondary as PGMenuSecondary,
    MenuClose,
} from '@paygreen/paygreen-ui';
import SearchBlock from '../SearchBlock';

const MenuSecondary = ({ isOpen, setMenuOpened, ...rest }) => {
    return (
        <PGMenuSecondary
            {...rest}
            isOpen={isOpen}
            closeComponent={
                <MenuClose
                    onClick={() => setMenuOpened(false)}
                    colorTheme="secondary"
                />
            }
        >
            <SearchBlock inputId="modalInputDesktop" />
        </PGMenuSecondary>
    );
};

MenuSecondary.propTypes = {
    setMenuOpened: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
};

MenuSecondary.defaultProps = {
    isOpen: false,
};

export default MenuSecondary;
