import React from 'react';
import { Logo } from '@paygreen/paygreen-ui';

const TwIcon = () => (
    <Logo hasHoverColor={true} blockWidth="md">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 488">
            <path
                d="M244,0C109.2,0,0,109.2,0,244s109.2,244,244,244s244-109.2,244-244S378.8,0,244,0z M383.3,190.3l0.2,9
                c0,91.6-69.7,197.3-197.3,197.3c-37.7,0-74.6-10.8-106.3-31.2c5.5,0.6,11,1,16.5,1c31.2,0.1,61.5-10.3,86.1-29.6
                c-29.7-0.6-55.7-19.9-64.8-48.2c4.3,0.8,8.7,1.3,13.1,1.3c6.2,0,12.3-0.8,18.3-2.4c-32.3-6.6-55.6-35-55.6-68v-0.9
                c9.6,5.3,20.4,8.3,31.4,8.7c-19.3-12.9-30.9-34.5-30.8-57.7c0-12.2,3.2-24.2,9.4-34.8c35.2,43.3,87.2,69.6,142.9,72.4
                c-8.7-37.3,14.5-74.6,51.8-83.3c24.1-5.6,49.4,2,66.3,20.1c15.5-3.1,30.4-8.8,44.1-16.8c-5.2,16.1-16,29.7-30.5,38.3
                c13.7-1.6,27.1-5.3,39.8-10.9C408.6,168.5,396.9,180.6,383.3,190.3z"
            />
        </svg>
    </Logo>
);

export default TwIcon;
