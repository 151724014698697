import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    Input,
    SearchBoldIcon,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import useWindowSize from '../utils/useWindowSize';
import SearchResults from './SearchResults';
import ModalBlock from './ModalBlock';

const SearchBlock = ({ inputId, ...rest }) => {
    const intl = useIntl();
    const size = useWindowSize();

    /**
     * Search field and results
     */
    const [questions, setQuestions] = useState([]);
    const [searchTerms, setSearchTerms] = useState('');
    const maxResultsPopin = 3;

    /**
     * Manage Modal and clear datas
     */
    const [isOpen, setIsOpen] = useState(false);
    const clearSearch = () => {
        setSearchTerms('');
        setQuestions([]);
    };
    const openModal = () => {
        setIsOpen(true);
        document.getElementById(inputId).focus();
    };
    const closeModal = () => {
        setIsOpen(false);
        setTimeout(() => clearSearch(), 300); // needed to wait for modal closing transition before cleaning datas properly
    };

    const CancelButton = () => {
        return (
            <button type="button" onClick={() => closeModal()}>
                <Button buttonSize="sm" colorTheme="tertiary">
                    {intl.formatMessage({
                        id: 'searchbar.button',
                    })}
                </Button>
            </button>
        );
    };

    const OpenButton = () => {
        return (
            <>
                <button
                    type="button"
                    onClick={() => openModal()}
                    className="hideOnSmallScreen"
                >
                    <Input
                        placeholder={intl.formatMessage({
                            id: 'searchbar.placeholder',
                        })}
                        blockWidth="sm"
                    />
                </button>

                <button
                    type="button"
                    onClick={() => openModal()}
                    className="hideOnBigScreen"
                >
                    <SearchBoldIcon
                        data-testid="search-btn"
                        iconSize="sm"
                        hasBackground={true}
                        hasHover={true}
                    />
                </button>
            </>
        );
    };

    return (
        <div {...rest}>
            <OpenButton />

            <ModalBlock
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                closeWithAction={closeModal}
            >
                <SearchResults
                    onResponse={setQuestions}
                    onChange={setSearchTerms}
                    searchTerms={searchTerms}
                    questions={questions}
                    inputId={inputId}
                    maxResultsPopin={maxResultsPopin}
                >
                    <CancelButton />
                </SearchResults>

                {questions.length > maxResultsPopin ? null : (
                    <ButtonGroup
                        align="center"
                        marginTop="sm"
                        marginBottom={
                            size.width <
                            parseInt(ThemeDefault.query.min.lg.slice(11))
                                ? 'md'
                                : 'xs'
                        }
                    >
                        <CancelButton />
                    </ButtonGroup>
                )}
            </ModalBlock>
        </div>
    );
};

SearchBlock.propTypes = {
    /**
     * this props has to be unique each time searchBlock is used to perform action on specific input in modal
     */
    inputId: PropTypes.string.isRequired,
};

export default SearchBlock;
