/**
 * Return an Object of the following form : {uid, title, description, icon, pathTo}
 * @param {Object} e A document of Prismic's CustomType Question retrieved with a query to Prismic API
 */
export function parsePrismic(e) {
    return {
        uid: e.uid,
        title: e.data.title,
        description: e.data.description,
        icon: e.data['parent-category'].data['parent-theme'].data.icon,
        pathTo:
            '/' +
            e.data['parent-category'].data['parent-theme'].uid +
            '/' +
            e.data['parent-category'].uid +
            '/' +
            e.uid +
            '/',
    };
}

/**
 * Return an Object of the following form : {uid, title, description, icon, pathTo}
 * @param {Object} e A document of Prismic's CustomType Question retrieved with a GraphQL query
 */
export function parseGraphQL(e) {
    return {
        uid: e._meta.uid,
        title: e.title,
        description: e.description,
        icon: e.parentCategory.parentTheme.icon,
        pathTo:
            '/' +
            e.parentCategory.parentTheme._meta.uid +
            '/' +
            e.parentCategory._meta.uid +
            '/' +
            e._meta.uid +
            '/',
    };
}
