import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const LinkDispatcher = ({ url, children }) => {
    if (!url) {
        return null;
    }

    let link = null;

    if (url.startsWith('/')) {
        link = <Link to={url}>{children}</Link>;
    } else if (url.startsWith('#')) {
        link = <a href={url}>
            {children}
        </a>;
    } else {
        link = <a href={url} target="_blank" rel="noopener noreferrer">
            {children}
        </a>;
    }

    return link;
};

LinkDispatcher.propTypes = {
    /**
     * Url to check and return
     */
    url: PropTypes.string.isRequired,
};

export default LinkDispatcher;
