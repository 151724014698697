import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { MenuHamburger } from '@paygreen/paygreen-ui';
import { LogoPayGreen } from './';
import SearchBlock from '../SearchBlock';

const TopbarMobile = props => {
    return (
        <>
            <div className="main-nav">
                <MenuHamburger
                    onClick={props.togglePrimaryMenu}
                    isOpen={props.primaryMenuOpened}
                />

                <GatsbyLink to="/">
                    <LogoPayGreen />
                </GatsbyLink>

                <SearchBlock
                    inputId="modalInputMobile"
                    className="hideOnBigScreen"
                />
            </div>
        </>
    );
};

TopbarMobile.propTypes = {
    togglePrimaryMenu: PropTypes.func.isRequired,
    toggleSecondaryMenu: PropTypes.func.isRequired,
    primaryMenuOpened: PropTypes.bool,
};

TopbarMobile.defaultProps = {
    primaryMenuOpened: false,
};

export default TopbarMobile;
