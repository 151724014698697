import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { fetchQuestions } from '../utils/prismicQueries';
import useDebounce from '../utils/debouncer';
import QuestionList from './QuestionList';
import { parsePrismic } from '../utils/parsers/questionsParser';
import { BuildDateContext } from './Layout';
import { Button, ButtonGroup, Input, Loader } from '@paygreen/paygreen-ui';

const SearchResults = ({
    children,
    onResponse,
    onChange,
    inputId,
    questions,
    maxResultsPopin,
    searchTerms,
}) => {
    const intl = useIntl();
    const [inputSearchTerms, setInputSearchTerms] = useState(searchTerms);
    const [isSearching, setIsSearching] = useState(false);
    const buildDateContext = useContext(BuildDateContext);
    const debouncedSearchTerms = useDebounce(inputSearchTerms, 400);

    // we reset input value when resetting value via searchTerms on opening modal
    useEffect(() => {
        if (!searchTerms) {
            setInputSearchTerms('');
        }
    }, [searchTerms]);

    useEffect(() => {
        if (onChange) {
            onChange(debouncedSearchTerms && debouncedSearchTerms.trim());
        }

        if (
            (debouncedSearchTerms &&
                debouncedSearchTerms.trim().length === 0) ||
            !inputSearchTerms
        ) {
            onResponse([]);
        } else {
            setIsSearching(true);

            fetchQuestions(
                debouncedSearchTerms && debouncedSearchTerms.trim(),
                buildDateContext,
            )
                .then(results => {
                    const questions = [];

                    results.forEach(question => {
                        questions.push(parsePrismic(question));
                    });

                    onResponse(questions);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setIsSearching(false);
                });
        }
    }, [
        setIsSearching,
        onChange,
        onResponse,
        debouncedSearchTerms,
        buildDateContext,
        inputSearchTerms,
    ]);

    return (
        <>
            <Input
                data-testid="searchbar"
                onChange={e => {
                    setInputSearchTerms(e.target.value);
                }}
                value={inputSearchTerms}
                placeholder={intl.formatMessage({
                    id: 'searchbar.placeholder',
                })}
                id={inputId}
                blockWidth="lg"
            />

            {searchTerms && searchTerms.length > 0 ? (
                <>
                    {isSearching ? (
                        <Loader
                            loaderSize="lg"
                            paddingTop="md"
                            paddingBottom="md"
                        />
                    ) : (
                        <QuestionList
                            data-testid="questions-menulist"
                            questions={questions.slice(0, maxResultsPopin)}
                        />
                    )}

                    {questions.length > maxResultsPopin ? (
                        <ButtonGroup
                            marginTop="sm"
                            marginBottom="sm"
                            align="center"
                        >
                            {children}
                            
                            <Link
                                to={
                                    searchTerms.split(' ') > 1
                                        ? '/search?w=' +
                                          searchTerms.replace(' ', '+')
                                        : '/search?w=' + searchTerms
                                }
                            >
                                <Button buttonSize="sm">
                                    {intl.formatMessage({
                                        id: 'see.more',
                                    })}
                                </Button>
                            </Link>
                        </ButtonGroup>
                    ) : null}
                </>
            ) : null}
        </>
    );
};

SearchResults.propTypes = {
    /**
     * this props has to be unique each time searchResults is used to perform action on specific input in modal
     */
    inputId: PropTypes.string.isRequired,
    onResponse: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    searchTerms: PropTypes.string,
    questions: PropTypes.array,
    maxResultsPopin: PropTypes.number,
};

SearchResults.defaultProps = {
    searchTerms: '',
    questions: [],
    maxResultsPopin: 3,
};

export default SearchResults;
