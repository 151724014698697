import Prismic from 'prismic-javascript';
import client from '../../prismic-config';
const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const tagNameToProduction =
    process.env.NAME_TAG_TO_QUESTION_PRODUCTION || 'valid-to-prod';

export function fetchQuestions(terms, buildTime) {
    return new Promise((resolve, reject) => {
        // Search documents 'question' where all terms are found
        let predicates = [
            Prismic.Predicates.at('document.type', 'question'),
            Prismic.Predicates.fulltext('document', terms),
            Prismic.Predicates.dateBefore(
                'document.first_publication_date',
                new Date(buildTime),
            ),
        ];
        // If on production, select only questions with tagNameToProduction
        if (activeEnv === 'production') {
            predicates.push(
                Prismic.Predicates.at('document.tags', [tagNameToProduction]),
            );
        }

        client
            .query(
                predicates,
                // Search options to sort result
                {
                    pageSize: 10,
                    orderings: '[my.question.uid]',
                    fetchLinks: [
                        'category.parent-theme',
                        'theme.icon',
                        'theme.name',
                    ],
                },
            )
            .then(response => {
                resolve(response.results);
            })
            .catch(error => {
                reject(error);
            });
    });
}

/**
 * WIP - This function is more like fetchOldestPublished than fetchMostViewed, waiting for Google Analytics use
 * @param {string} location Tell where are we looking to query
 * @param {string[]} categoriesIDs An array of categories related to questions
 */
export function fetchMostViewed(location, categoriesIDs) {
    return new Promise((resolve, reject) => {
        // If there are no categoriesID, query will return zero question
        if (categoriesIDs != null && categoriesIDs.length === 0) {
            resolve([]);
        }

        // Check if parameter location correctly setted
        if (location !== 'all' && location !== 'theme') {
            reject(new Error("Parameter 'location' must be 'all' or 'theme'!"));
        }

        // The query retrieve question
        let queryConfig = [
            Prismic.Predicates.at('document.type', 'question'),
            Prismic.Predicates.at('document.tags', [tagNameToProduction]),
        ];
        switch (location) {
            // If the location is 'theme', add a Predicate to precise the query
            case 'theme':
                queryConfig.push(
                    Prismic.Predicates.any(
                        'my.question.parent-category',
                        categoriesIDs,
                    ),
                );
                break;

            default:
                break;
        }

        client
            .query(queryConfig, {
                pageSize: 5,
                orderings: '[document.first_publication_date]',
                fetchLinks: [
                    'category.parent-theme',
                    'theme.uid',
                    'theme.icon',
                    'theme.name',
                ],
            })
            .then(response => {
                resolve(response.results);
            })
            .catch(error => {
                reject(error);
            });
    });
}

/**
 * Find a maximum of three similar questions from a given question's ID
 * @param {string} questionID The question's ID from which to search for similar questions
 */
export function findSimilar(questionID, buildTime) {
    // Search documents 'question' where all terms are found
    let predicates = [
        Prismic.Predicates.at('document.type', 'question'),
        Prismic.Predicates.similar(questionID, 50),
        Prismic.Predicates.dateBefore(
            'document.first_publication_date',
            new Date(buildTime),
        ),
    ];
    // If on production, select only questions with tagNameToProduction
    if (activeEnv === 'production') {
        predicates.push(
            Prismic.Predicates.at('document.tags', [tagNameToProduction]),
        );
    }

    return new Promise((resolve, reject) => {
        client
            .query(predicates, {
                pageSize: 3,
                orderings: '[document.first_publication_date]',
                fetchLinks: [
                    'category.name',
                    'category.parent-theme',
                    'theme.icon',
                    'theme.name',
                ],
            })
            .then(response => {
                resolve(response.results);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function fetchThemes(buildTime) {
    return new Promise((resolve, reject) => {
        let predicates = [
            // Search documents 'question' where all terms are found
            Prismic.Predicates.at('document.type', 'theme'),
            Prismic.Predicates.dateBefore(
                'document.first_publication_date',
                new Date(buildTime),
            ),
        ];
        // If on production, select only questions with tagNameToProduction
        if (activeEnv === 'production') {
            predicates.push(
                Prismic.Predicates.at('document.tags', [tagNameToProduction]),
            );
        }
        client
            .query(
                predicates,
                // Search options to sort result
                {
                    pageSize: 100,
                    orderings: '[my.theme.uid]',
                },
            )
            .then(response => {
                resolve(response.results);
            })
            .catch(error => {
                reject(error);
            });
    });
}
