import Prismic from 'prismic-javascript';

const apiEndpoint = 'https://paygreen-faq.cdn.prismic.io/api/v2';

var client = Prismic.client(apiEndpoint);

//Uncomment these lines if the repo is private and set the accessToken value
const accessToken = 'MC5YbXNfSFJRQUFDUUFqY3pq.77-9GkhSEu-_ve-_ve-_ve-_ve-_vWcOLmV877-977-977-9f--_vSXvv71_77-977-9ce-_vVARBe-_vQs'
client = Prismic.client(apiEndpoint, {accessToken})

export default client;