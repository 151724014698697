import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Overlay,
    CrossIcon,
} from '@paygreen/paygreen-ui';

const ModalBlock = ({ children, closeWithAction, isOpen }) => {
    return (
        <Modal isOpen={isOpen}>
            <Overlay opacityValue={30} onClick={() => closeWithAction()} />

            <ModalContent>
                <ModalHeader paddingBottom="none">
                    <CrossIcon
                        onClick={() => closeWithAction()}
                        iconSize="md"
                        colorPallet="wab"
                        style={{ cursor: 'pointer' }} // cannot use htmlTag=button because MenuGroup in Header hides automatically all icon inside button
                    />
                </ModalHeader>

                <ModalBody
                    paddingTop="none"
                    paddingBottom="sm"
                    paddingLateral="sm"
                >
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

ModalBlock.propTypes = {
    /**
     * Indicate to parent that the modal need action after close
     */
    closeWithAction: PropTypes.func,
    isOpen: PropTypes.bool,
};

ModalBlock.defaultProps = {
    isOpen: false,
};

export default ModalBlock;
