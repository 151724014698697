import React from 'react';
import { Logo } from '@paygreen/paygreen-ui';

const FbIcon = () => (
    <Logo hasHoverColor={true} blockWidth="md">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M257,12.3c-134.8,0-244,109.2-244,244s109.2,244,244,244s244-109.2,244-244S391.8,12.3,257,12.3z M340.5,142.6
                c-16.3,0-33.9,0-42.2,0c-8.4,0-16.8,8.7-16.8,15.1c0,6.4,0,43.1,0,43.1s52.2,0,58.9,0c-2.4,33-7.2,63.2-7.2,63.2h-52v187h-77.5
                V263.9H166v-63h37.7c0,0,0-42.1,0-51.5S201.8,77,283.1,77c10.9,0,35.7,0,57.4,0C340.5,104.4,340.5,134,340.5,142.6z"
            />
        </svg>
    </Logo>
);

export default FbIcon;
