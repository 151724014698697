import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import {
    MenuPrimary as PGMenuPrimary,
    MenuItem,
    MenuListItem,
    Menu,
    MenuList,
    Link as PGLink,
    PhoneIcon,
    OutIcon,
    IconLabel,
    Text,
    LetterIcon,
} from '@paygreen/paygreen-ui';
import IconByName from '../IconByName';
import LinkDispatcher from '../LinkDispatcher';

const subLinkGreyStyle = {
    link: {
        colorPallet: 'wab',
        colorWab: 'grey60',
    },
    icon: {
        iconSize: 'lg',
        colorPallet: 'wab',
        colorWab: 'grey50',
    },
    labelIcon: {
        iconSize: 'xs',
        marginRight: 'xs',
    },
};

const MenuPrimary = ({ themes, colorTheme, toggleMenu, ...rest }) => {
    const intl = useIntl();

    /**
     * Allow to add onClick to close menu if same page
     */
    const LinkWrapper = ({ children, to, ...rest }) => {
        return (
            <GatsbyLink onClick={toggleMenu} to={to} {...rest}>
                {children}
            </GatsbyLink>
        );
    };

    return (
        <PGMenuPrimary {...rest}>
            <Menu>
                <LinkWrapper to="/">
                    <MenuItem colorTheme={colorTheme}>
                        {intl.formatMessage({ id: 'home.title' })}
                    </MenuItem>
                </LinkWrapper>
            </Menu>

            <Menu>
                <MenuItem isClickable={false}>
                    {intl.formatMessage({ id: 'header.theme' })}
                </MenuItem>

                <MenuList colorTheme={colorTheme}>
                    {themes.map(theme => {
                        const elementColor =
                            theme.data['color-theme'] === 'default'
                                ? 'primary'
                                : theme.data['color-theme'];

                        return (
                            <LinkWrapper
                                to={'/' + theme.uid + '/'}
                                key={theme.uid}
                            >
                                <MenuListItem colorTheme={elementColor}>
                                    <IconByName
                                        name={theme.data.icon}
                                        colorTheme={elementColor}
                                        iconSize="lg"
                                    />

                                    <PGLink colorTheme={elementColor}>
                                        {theme.data.name}
                                    </PGLink>

                                    <Text>{theme.data.description}</Text>
                                </MenuListItem>
                            </LinkWrapper>
                        );
                    })}
                </MenuList>
            </Menu>

            <Menu>
                <MenuItem isClickable={false}>
                    {intl.formatMessage({ id: 'header.contact' })}
                </MenuItem>

                <MenuList colorTheme={colorTheme}>
                    <LinkDispatcher url="https://paygreen.fr/content/support">
                        <MenuListItem colorTheme={colorTheme}>
                            <PhoneIcon {...subLinkGreyStyle.icon} />

                            <PGLink {...subLinkGreyStyle.link}>
                                {intl.formatMessage({
                                    id: 'header.contact.bo',
                                })}
                            </PGLink>

                            <IconLabel colorTheme={colorTheme}>
                                <OutIcon
                                    {...subLinkGreyStyle.labelIcon}
                                    colorTheme={colorTheme}
                                />
                                BO
                            </IconLabel>
                        </MenuListItem>
                    </LinkDispatcher>

                    <LinkDispatcher url="https://paygreen.io/contact/?form=DE-PAIEMENT">
                        <MenuListItem colorTheme={colorTheme}>
                            <LetterIcon {...subLinkGreyStyle.icon} />

                            <PGLink {...subLinkGreyStyle.link}>
                                {intl.formatMessage({
                                    id: 'header.contact.io',
                                })}
                            </PGLink>

                            <IconLabel colorTheme={colorTheme}>
                                <OutIcon
                                    {...subLinkGreyStyle.labelIcon}
                                    colorTheme={colorTheme}
                                />
                                IO
                            </IconLabel>
                        </MenuListItem>
                    </LinkDispatcher>
                </MenuList>
            </Menu>
        </PGMenuPrimary>
    );
};

MenuPrimary.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    themes: PropTypes.array,
};

MenuPrimary.defaultProps = {
    isOpen: false,
    themes: [],
};

export default MenuPrimary;
