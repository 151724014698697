import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { math } from 'polished';
import { PGThemeProvider, ThemeDefault, Link } from '@paygreen/paygreen-ui';
import { GlobalStyle } from '../themes/GlobalStyle';
import bg from '../assets/images/bg.png';
import Header from './Header';
import Footer from './Footer';
import { BannerRGPD } from './menus';
import { CommonDataContext } from '../contexts/CommonDataContext';

const pageAnimation = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const Body = styled.div`
    background: url(${bg}) repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation-name: ${pageAnimation};
    animation-fill-mode: forwards;
    animation-duration: ${ThemeDefault.transition.sm};
    /* Wait for header style transition (sm) + smoothscroll duration (~0.6s) */
    animation-delay: ${math(ThemeDefault.transition.sm + ' + ' + 0.6)};
`;

const Main = styled.main`
    flex: 1;
    overflow-x: hidden;
    padding-top: 0.1px;
    padding-bottom: ${ThemeDefault.space.lg};
`;

export const BuildDateContext = React.createContext(null);

const Layout = ({ colorTheme, children }) => {
    const data = useStaticQuery(graphql`
        query {
            currentBuildDate {
                currentDate
            }
            allSitePage {
                edges {
                    node {
                        path
                        context {
                            uid
                        }
                    }
                }
            }
        }
    `);

    const allPath = data.allSitePage.edges.map(p => p.node);

    return (
        <Body>
            <GlobalStyle />

            <BuildDateContext.Provider
                value={data.currentBuildDate.currentDate}
            >
                <PGThemeProvider theme={ThemeDefault}>
                    <CommonDataContext.Provider value={allPath}>
                        <Header colorTheme={colorTheme} />

                        <Main>{children}</Main>

                        <Footer />

                        <BannerRGPD
                            theme={ThemeDefault}
                            cookie="user-has-accepted-cookies"
                        >
                            En poursuivant votre navigation sur ce site, vous
                            acceptez l'enregistrement et l'utilisation de
                            cookies.{' '}
                            <a
                                data-testid="rgpd-link-cgu"
                                href="https://paygreen.io/mentions-legales/"
                            >
                                <Link>Plus d'informations ici.</Link>
                            </a>
                        </BannerRGPD>
                    </CommonDataContext.Provider>
                </PGThemeProvider>
            </BuildDateContext.Provider>
        </Body>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    colorTheme: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'quinary',
    ]),
};

Layout.defaultProps = {
    colorTheme: 'primary',
};

export default Layout;
