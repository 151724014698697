import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CookieBanner from 'react-cookie-banner';

const BannerRGPDBase = styled.div`
    position: fixed;
    z-index: ${props => props.theme.zindex.header};
    width: 100%;
    bottom: 0;
    padding: ${props => props.theme.space.sm};
    font-size: ${props => props.theme.font.size.xs};
    background-color: ${props => props.theme.wab.grey10};
    border-top: solid
        ${props => props.theme.line + ' ' + props.theme.wab.grey20};

    @media (${props => props.theme.query.min.md}) {
        display: flex;
        align-items: center;
    }

    p {
        margin: 0;
        line-height: ${props => props.theme.font.lineHeight.md};
        color: ${props => props.theme.wab.grey50};
    }

    button {
        display: block;
        font-size: ${props => props.theme.font.size.xxs};
        font-weight: ${props => props.theme.font.weight.bold};
        text-transform: uppercase;
        letter-spacing: ${props => props.theme.spacing};
        background-color: ${props => props.theme.color.primary.main};
        color: ${props => props.theme.wab.white00};
        padding: ${props => props.theme.space.sm};
        margin: ${props => props.theme.space.sm} auto;
        margin-bottom: 0;
        border-radius: ${props => props.theme.radius.sm};
        transition: all ${props => props.theme.transition.xs};

        &:hover,
        &:active,
        &:focus {
            background-color: ${props => props.theme.color.tertiary.main};
        }

        @media (${props => props.theme.query.min.md}) {
            margin: 0;
            margin-left: ${props => props.theme.space.md};
        }
    }
`;

const BannerRGPD = ({ children, acceptButton, cookie, ...rest }) => {
    return (
        <CookieBanner cookie={cookie}>
            {onAccept => (
                <BannerRGPDBase {...rest}>
                    <p>{children}</p>

                    <button onClick={onAccept} type="button">
                        {acceptButton}
                    </button>
                </BannerRGPDBase>
            )}
        </CookieBanner>
    );
};

BannerRGPD.propTypes = {
    cookie: PropTypes.string.isRequired,
    acceptButton: PropTypes.string,
};

BannerRGPD.defaultProps = {
    acceptButton: 'Accepter',
};

export default BannerRGPD;
