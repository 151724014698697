import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import React, { useState, useEffect, useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import {
    ThemeDefault,
    Footer as PGFooter,
    ListHorizontal,
    Link as PGLink,
    Text,
    Grid,
    Title,
    InternalGrid,
    FooterList,
    IconLabel,
    OutIcon,
} from '@paygreen/paygreen-ui';
import FbIcon from '../assets/icons/social-icons/Fb';
import TwIcon from '../assets/icons/social-icons/Tw';
import InIcon from '../assets/icons/social-icons/In';
import { fetchThemes } from '../utils/prismicQueries';
import { BuildDateContext } from './Layout';
import LinkDispatcher from './LinkDispatcher';

const titleStyles = {
    htmlTag: 'h4',
    align: 'center',
    textSize: 'xs',
    colorTheme: 'secondary',
    colorWab: 'grey30',
    hasUnderline: true,
    marginBottom: 'xs',
};

const linkStyles = {
    hasUnderline: false,
    colorPallet: 'wab',
    colorWab: 'grey50',
};

const listStyles = {
    align: 'center',
    textSize: 'xs',
    marginBottom: 'md',
};

const SocialLink = styled.a`
    display: block;

    span {
        width: ${ThemeDefault.space.lg};
        fill: ${ThemeDefault.color.primary.main};
    }
`;

const Footer = () => {
    const buildDateContext = useContext(BuildDateContext);

    const intl = useIntl();

    const [themes, setThemes] = useState([]);

    useEffect(() => {
        fetchThemes(buildDateContext)
            .then(results => {
                setThemes(results);
            })
            .catch(error => console.log('An error has occured : ', error));
    }, [buildDateContext]);

    return (
        <PGFooter topStyle="right">
            <Grid childrenFlex={1}>
                <div>
                    <Title {...titleStyles}>
                        {intl.formatMessage({
                            id: 'footer.listtitle.showcasewebsite',
                        })}
                    </Title>
                    <FooterList {...listStyles}>
                        <LinkDispatcher data-testid="footer-link-home" url="/">
                            <PGLink {...linkStyles}>
                                {intl.formatMessage({
                                    id: 'footer.listcontent.home',
                                })}
                            </PGLink>
                        </LinkDispatcher>

                        <LinkDispatcher
                            data-testid="footer-link-search"
                            url="/search"
                        >
                            <PGLink {...linkStyles}>
                                {intl.formatMessage({
                                    id: 'footer.listcontent.search',
                                })}
                            </PGLink>
                        </LinkDispatcher>

                        <LinkDispatcher
                            data-testid="footer-link-paygreenio"
                            url="https://paygreen.io/"
                        >
                            <InternalGrid
                                alignItems="center"
                                justifyContent="center"
                                childrenMarginLateral="xs"
                                childrenMarginTop="xs"
                            >
                                <PGLink {...linkStyles}>
                                    {intl.formatMessage({
                                        id: 'footer.listcontent.paygreenio',
                                    })}
                                </PGLink>
                                <IconLabel>
                                    <OutIcon
                                        iconSize="xs"
                                        marginRight="xs"
                                        colorTheme="primary"
                                    />
                                    {intl.formatMessage({
                                        id: 'footer.iconlabel.paygreenio',
                                    })}
                                </IconLabel>
                            </InternalGrid>
                        </LinkDispatcher>

                        <LinkDispatcher
                            data-testid="footer-link-bo"
                            url="https://paygreen.fr/login"
                        >
                            <InternalGrid
                                alignItems="center"
                                justifyContent="center"
                                childrenMarginLateral="xs"
                                childrenMarginTop="xs"
                            >
                                <PGLink {...linkStyles}>
                                    {intl.formatMessage({
                                        id: 'footer.listcontent.bo',
                                    })}
                                </PGLink>
                                <IconLabel>
                                    <OutIcon
                                        iconSize="xs"
                                        marginRight="xs"
                                        colorTheme="primary"
                                    />
                                    {intl.formatMessage({
                                        id: 'footer.iconlabel.bo',
                                    })}
                                </IconLabel>
                            </InternalGrid>
                        </LinkDispatcher>
                    </FooterList>
                </div>

                <div>
                    <Title {...titleStyles}>
                        {intl.formatMessage({
                            id: 'footer.listtitle.developer',
                        })}
                    </Title>
                    <FooterList {...listStyles}>
                        <LinkDispatcher
                            data-testid="footer-link-apidocs"
                            url="https://paygreen.fr/documentation/api-documentation-categorie?cat=paiement"
                        >
                            <InternalGrid
                                alignItems="center"
                                justifyContent="center"
                                childrenMarginLateral="xs"
                                childrenMarginTop="xs"
                            >
                                <PGLink {...linkStyles}>
                                    {intl.formatMessage({
                                        id: 'footer.listcontent.apidocs',
                                    })}
                                </PGLink>

                                <IconLabel>
                                    <OutIcon
                                        iconSize="xs"
                                        marginRight="xs"
                                        colorTheme="primary"
                                    />
                                    {intl.formatMessage({
                                        id: 'footer.iconlabel.apidocs',
                                    })}
                                </IconLabel>
                            </InternalGrid>
                        </LinkDispatcher>

                        {themes.map(theme => (
                            <GatsbyLink
                                data-testid="footer-link-apidocs"
                                to={'/' + theme.uid}
                                key={theme.uid}
                            >
                                <PGLink {...linkStyles}>
                                    {theme.data.name}
                                </PGLink>
                            </GatsbyLink>
                        ))}
                    </FooterList>
                </div>

                <div>
                    <Title {...titleStyles}>
                        {intl.formatMessage({
                            id: 'footer.listtitle.socialmedias',
                        })}
                    </Title>

                    <InternalGrid
                        justifyContent="center"
                        childrenMarginLateral="sm"
                    >
                        <SocialLink
                            data-testid="footer-link-facebook"
                            href="https://www.facebook.com/PayGreen.fr/"
                            target="_blank"
                        >
                            <FbIcon />
                        </SocialLink>

                        <SocialLink
                            data-testid="footer-link-twitter"
                            href="https://twitter.com/paygreen_fr?lang=fr"
                            target="_blank"
                        >
                            <TwIcon />
                        </SocialLink>

                        <SocialLink
                            data-testid="footer-link-linkedin"
                            href="https://fr.linkedin.com/company/paygreen"
                            target="_blank"
                        >
                            <InIcon />
                        </SocialLink>
                    </InternalGrid>
                </div>
            </Grid>
            <Text
                align="center"
                textSize="xxs"
                colorWab="grey40"
                hasUppercase={true}
            >
                © 2020 – PayGreen
            </Text>
            <ListHorizontal
                align="center"
                textSize="xxs"
                colorWab="grey50"
                marginBottom="xs"
            >
                <a
                    data-testid="footer-link-cgu"
                    href="https://paygreen.io/cgu/"
                >
                    <PGLink
                        hasUnderline={false}
                        colorPallet="wab"
                        colorWab="grey60"
                    >
                        {intl.formatMessage({
                            id: 'footer.links.tou',
                        })}
                    </PGLink>
                </a>

                <a
                    data-testid="footer-link-mentionlegale"
                    href="https://paygreen.io/mentions-legales/"
                >
                    <PGLink
                        hasUnderline={false}
                        colorPallet="wab"
                        colorWab="grey60"
                    >
                        {intl.formatMessage({
                            id: 'footer.links.ln',
                        })}
                    </PGLink>
                </a>
            </ListHorizontal>
            <div></div>
        </PGFooter>
    );
};

Footer.propTypes = {
    siteTitle: PropTypes.string,
};

Footer.defaultProps = {
    siteTitle: ``,
};

export default Footer;
