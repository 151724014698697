/**
 * Method from 'https://www.w3resource.com/javascript-exercises/javascript-string-exercise-16.php'
 */
export default function truncate(str, length = 100, ending = '...') {
    if (str === null) {
        return '';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}
