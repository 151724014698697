import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Header as PGHeader, MenuGroup, Dot } from '@paygreen/paygreen-ui';
import { fetchThemes } from '../utils/prismicQueries';
import { BuildDateContext } from './Layout';
import { MenuPrimary, MenuSecondary, TopbarMobile } from './menus';

const Header = ({ colorTheme }) => {
    const buildDateContext = useContext(BuildDateContext);

    const [themes, setThemes] = useState([]);
    const [primaryMenuOpened, setPrimaryMenuOpened] = useState(false);
    const [secondaryMenuOpened, setSecondaryMenuOpened] = useState(false);

    const togglePrimaryMenu = () => {
        setPrimaryMenuOpened(!primaryMenuOpened);

        if (secondaryMenuOpened) {
            setSecondaryMenuOpened(false);
        }
    };

    const toggleSecondaryMenu = () => {
        setSecondaryMenuOpened(!secondaryMenuOpened);

        if (primaryMenuOpened) {
            setPrimaryMenuOpened(false);
        }
    };

    useEffect(() => {}, [primaryMenuOpened]);
    useEffect(() => {
        fetchThemes(buildDateContext)
            .then(results => {
                setThemes(results);
            })
            .catch(error => console.log('An error has occurred: ', error));
    }, [buildDateContext]);

    return (
        <PGHeader>
            <MenuGroup>
                <TopbarMobile
                    primaryMenuOpened={primaryMenuOpened}
                    togglePrimaryMenu={togglePrimaryMenu}
                    toggleSecondaryMenu={toggleSecondaryMenu}
                />

                <MenuPrimary
                    isOpen={primaryMenuOpened}
                    toggleMenu={togglePrimaryMenu}
                    themes={themes}
                    colorTheme={colorTheme}
                />

                <Dot
                    className="hideOnSmallScreen"
                    dotSize="md"
                    colorPallet="wab"
                    colorWab="grey10"
                    marginLeft="lg"
                    marginRight="lg"
                />

                <MenuSecondary
                    isOpen={secondaryMenuOpened}
                    setMenuOpened={setSecondaryMenuOpened}
                />
            </MenuGroup>
        </PGHeader>
    );
};

Header.propTypes = {
    colorTheme: PropTypes.string,
};

Header.defaultProps = {
    colorTheme: 'primary',
};

export default Header;
