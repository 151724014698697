import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';

function SEO({
    description,
    lang,
    metaTitle,
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
}) {
    const intl = useIntl();

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle + intl.formatMessage({ id: 'helmet.title.site' })}
            titleTemplate={`%s`}
        >
            <meta name="description" content={description} />

            <meta property="og:type" content="website" />

            {ogUrl && <meta property="og:url" content={ogUrl} />}

            {ogTitle && <meta property="og:title" content={ogTitle} />}

            {ogDescription && (
                <meta property="og:description" content={ogDescription} />
            )}

            {ogImage && ogImage.url && (
                <meta property="og:image" content={ogImage.url} />
            )}

            <meta
                name="twitter:card"
                content={intl.formatMessage({ id: 'seo.twitter.card' })}
            />

            <meta
                name="twitter:site"
                content={intl.formatMessage({ id: 'seo.twitter.sitecreator' })}
            />

            <meta
                name="twitter:creator"
                content={intl.formatMessage({ id: 'seo.twitter.sitecreator' })}
            />

            {ogTitle && <meta name="twitter:title" content={ogTitle} />}

            {ogDescription && (
                <meta name="twitter:description" content={ogDescription} />
            )}

            {ogImage && ogImage.url && (
                <meta name="twitter:image" content={ogImage.url} />
            )}
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `fr`,
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    metaTitle: PropTypes.string.isRequired,
};

export default SEO;
