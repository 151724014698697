import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as PgUI from '@paygreen/paygreen-ui';

const IconByName = ({ name, ...rest }) => {
    const defaultIcon = 'CheckBoldIcon';
    const Icone = useMemo(() => PgUI[name] || PgUI[defaultIcon], [name]);

    return <Icone {...rest} /> || null;
};

IconByName.propTypes = {
    /**
     * Name of the Icon of PG UI
     */
    name: PropTypes.string.isRequired,
};

export default IconByName;
