import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { Link, List, ListItem, Title, Text } from '@paygreen/paygreen-ui';
import truncate from '../utils/truncater';

const QuestionList = ({ questions, colorTheme }) => {
    const intl = useIntl();

    if (!questions) {
        return null;
    }

    if (questions.length === 0) {
        return (
            <Text align="center" textSize="sm">
                {intl.formatMessage({ id: 'search.noresult' })}
            </Text>
        );
    }

    return (
        <List
            data-testid="questions-list"
            hasDashed={false}
            colorTheme={colorTheme}
            bulletSize="sm"
            marginTop="xs"
            marginBottom="xs"
        >
            {questions.map(({ uid, title, description, pathTo }) => (
                <ListItem
                    key={uid}
                    colorTheme={colorTheme}
                    bulletSize="sm"
                    marginBottom="md"
                >
                    <Title htmlTag="h3" textSize="md" marginBottom="none">
                        <GatsbyLink to={pathTo}>
                            <Link hasUnderline={false} colorTheme={colorTheme}>
                                {title}
                            </Link>
                        </GatsbyLink>
                    </Title>

                    {description ? (
                        <Text textSize="sm" marginTop="xs">
                            {truncate(description)}
                        </Text>
                    ) : null}
                </ListItem>
            ))}
        </List>
    );
};

export default QuestionList;
